import React from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import CartItem from './CartItem/CartItem';

const Cart = ({ cart, handleUpdateCartQty, handleRemoveFromCart, handleEmptyCart }) => {    
    
    const classes = useStyles();

    const EmptyCart = () => (
        <>
        <Typography variant="subtitle1" className={classes.szoveg}>
            Jelenleg üres a kosarad            
        </Typography>
        <Typography variant="subtitle1">
            <Link to="/" className={classes.link}>
                Vissza a vásárláshoz 
            </Link>
        </Typography>
        </>
    );
    

    const FilledCart = () => (
        <>
            <Grid container spacing={3}>
                {cart.line_items.map((item) => (
                    <Grid item xs={12} sm={4} key={item.id}>
                        <div>
                        <CartItem item={item} handleUpdateCartQty={handleUpdateCartQty} handleRemoveFromCart={handleRemoveFromCart} />
                        </div>
                    </Grid>
                ))}
            </Grid>
            <div className={classes.cardDetails}>
                <Typography variant="h4">
                    Teljes összeg: {cart.subtotal.raw} Ft
                </Typography>
                <div>
                    <Button className={classes.emptyButton} size="large" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>
                        Kosár törlése
                    </Button>
                    <Button component={Link} to="/fizetes" className={classes.checkoutButton} size="large" type="button" variant="contained" color="primary">
                        Fizetés
                    </Button>
                </div>
            </div>
        </>
    );      
    
    if(!cart.line_items) return 'Az oldal töltődik...';

    return (
        <Container> 
            <div className={classes.toolBar}/>
            <Typography className={classes.title} variant="h3" gutterBottom>
                Kosár
            </Typography>
            { !cart.line_items.length ? <EmptyCart /> : <FilledCart />}
        </Container>
    )
}

export default Cart
