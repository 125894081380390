import React, { useState, useEffect } from 'react'
import { InputLabel, Select, MenuItem, Button, Grid, Typography } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { commerce } from '../../lib/commerce'
import { Link } from 'react-router-dom';
import FormInput from './CustomTextField'

const AddressForm = ({ checkoutToken, next }) => {
    const methods = useForm();

    const fetchShippingOptions = async (checkoutTokenId, country, stateProvince = null) => {
        const options = await commerce.checkout.getShippingOptions(checkoutTokenId, { country });
    
        console.log(options)
      };

    useEffect(() => {
        fetchShippingOptions(checkoutToken.id, 'HU');
    }, [])
    
    return (
        <>
           <Typography variant="h6" gutterBottom>
               Szállítási Cím
           </Typography>
           <FormProvider { ...methods}>
                <form onSubmit={methods.handleSubmit((data) => next(data))}>
                    <Grid container spacing={3}>
                        <FormInput required name='familyname' label='Vezetéknév'/>
                        <FormInput required name='givenname' label='Keresztnév'/>
                        <FormInput required name='email' label='E-mail'/>
                        <FormInput required name='country' label='Ország'/>                        
                        <FormInput required name='city' label='Város'/>
                        <FormInput required name='postalcode' label='Irányítószám'/>
                        <FormInput required name='streetaddress' label='Cím'/>                                              
                    </Grid>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between '}}>
                        <Button component={Link} to="/cart" variant="outlined">Vissza a Kosárhoz</Button>
                        <Button type="submit" variant="contained" color="primary">Tovább</Button>
                    </div>
                </form>
           </FormProvider>

        </>
    )
}

export default AddressForm
