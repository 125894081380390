import React from 'react';
import { Typography, Button, Divider} from '@material-ui/core';
import { Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';

import Review from './Review'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const PaymentForm = ({ checkoutToken, backStep, shippingData, onCaptureCheckout, nextStep, timeout }) => {
    const handleSubmit = async (event, elements, stripe) => {
        event.preventDefault();

        if(!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card: cardElement });

        if(error) {
            console.log(error);
        } else {
            const orderData = {
                line_items: checkoutToken.live.line_items,
                customer: { firstname: shippingData.givenname, lastname: shippingData.familyname, email: shippingData.email},
                shipping: {
                     name: 'General',
                     country: 'HU', //hardcode
                     town_city: shippingData.city,
                     postal_zip_code: shippingData.postalcode,
                     street: shippingData.streetaddress, 
                },
                fulfillment: { shipping_method: 'ship_8XxzoBLjKlPQAZ' }, //hardcode
                payment: {
                    gateway: 'stripe',
                    stripe: {
                        payment_method_id: paymentMethod.id
                    }
                }
            }
            
            onCaptureCheckout(checkoutToken.id, orderData);

            timeout();

            nextStep();

        }
    }
    return (
        <>
            <Review checkoutToken={checkoutToken}/>
            <Divider/>
            <Typography variant="h6" gutterBottom style={{ margin: '20px 0' }}>
                Fizetési eszköz
            </Typography>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {( { elements, stripe }) => (
                        <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                            <CardElement/>
                            <br /> <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={backStep}>
                                    Vissza
                                </Button>
                                <Button type="submit" variant="contained" disabled={!stripe} color="primary">
                                    Fizetés: {checkoutToken.live.subtotal.raw} Ft
                                </Button>
                            </div>
                        </form>
                    )}
                </ElementsConsumer>
            </Elements>
        </>
    )
}

export default PaymentForm
