import React, { useState, useEffect } from 'react';
import { commerce } from './lib/commerce';
import { Products, Navbar, Cart, Checkout } from './Components';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './styles.css';

 
const App = () => {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [order, setOrder] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const fetchProducts = async () => {
        const { data } = await commerce.products.list();

        setProducts(data);
    }

    const fetchCart = async () => {
        setCart(await commerce.cart.retrieve());
    }

    const handleAddToCart = async (productId, quantity) => {
        const { cart } = await commerce.cart.add(productId, quantity);

        setCart(cart);
    }

    const handleUpdateCartQty = async (productId, quantity) => {
        
        const response = await commerce.cart.update(productId, { quantity });
        
        
    
        setCart(response.cart);
    }

    const handleRemoveFromCart = async (productId) => {
        const { cart } = await commerce.cart.remove(productId);

        setCart(cart);
    }

    const handleEmptyCart = async (productId) => {
        const { cart } = await commerce.cart.empty();

        setCart(cart);
    }

    const refreshCart = async () => {
        const newCart = await commerce.cart.refresh();

        setCart(newCart);
    }

    const handleCaptureCheckout = async (chechkoutTokenId, newOrder) => {
        try {
            const incomingOrder = await commerce.checkout.capture(chechkoutTokenId, newOrder);
            console.log('fasz');
            console.log(incomingOrder);
            console.log('fasz');

            setOrder(incomingOrder);
            refreshCart();
        } catch (error) {
            setErrorMessage(error.data.error.message);
            console.log(chechkoutTokenId)
            console.log(newOrder)
            console.log(error.data.error.message)
        }
    }

    useEffect(() =>{

        fetchProducts();
        fetchCart();

    },[]);

    console.log(cart);

    
    return (
        <Router>
            <div>
                <Navbar totalItems={cart.total_items}/>
                <div style={{ paddingTop:64}}>
                <Switch>
                    <Route exact path="/">
                        <Products products={products} onAddToCart={handleAddToCart}/>
                    </Route>
                    <Route exact path="/cart">
                        <Cart cart={cart} handleUpdateCartQty={handleUpdateCartQty} handleRemoveFromCart={handleRemoveFromCart} handleEmptyCart={handleEmptyCart} />
                    </Route>
                    <Route exact path="/fizetes">
                        <Checkout 
                            cart={cart}
                            order={order}
                            onCaptureCheckout={handleCaptureCheckout}
                            error={errorMessage}
                        />
                    </Route>      
                </Switch>
                </div>                
            </div>
        </Router>
        
    )
}

export default App;
