import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css'; 

import App from './App';

const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
  });

ReactDOM.render(<ThemeProvider theme={darkTheme}><App /></ThemeProvider>, document.getElementById('root'));